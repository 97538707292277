import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-touchlesslanding',
  templateUrl: './touchlesslanding.component.html',
  styleUrls: ['./touchlesslanding.component.scss']
})
export class TouchlesslandingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
